const _percentageFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    signDisplay: "exceptZero",
});
const _priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 0
});
const _priceFormatter2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2
});


const _priceFormatter_2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2
});
const _priceFormatter_4 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 4
});

export const formatPrice = (value, places) => {
    if (value == null || value == undefined) {
        return null;
    } else if (places == 2) {
        //return value?.toFixed(0);
        return _priceFormatter_2.format(value || 0);
    } else if (places == 4) {
        //return value?.toFixed(0);
        return _priceFormatter_4.format(value || 0);
    } else {
        //return value?.toFixed(0);
        return _priceFormatter.format(value || 0);
    }
}

export const formatPrice2 = (value) => {
    //return value?.toFixed(0);
    return _priceFormatter2.format(value || 0);
}

export const formatNumber = (value, places) => {
    return value?.toFixed(places || 0);
}

Math.round_ex = function (number, places) {
    //return +(Math.round(number + "e+" + places) + "e-" + places);
    return Number(number.toFixed(places || 0));
}

export const formatPercentage = (value, places) => {
    return formatNumber((value || 0) * 100, (places == null ? 2 : places)) + "%";
}
