import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { MenuOutlined } from '@mui/icons-material'

import './global.css'
import { UsersApiHelper } from './api/ApiHelper'
import { Button, Drawer, } from '@mui/material'
import YieldsDashboard from './yields/YieldsDashboard'
import DashboardContextPicker from './components/DashboardContextPicker'
import { AuthenticationContext } from '@datanac/datanac-mui-toolkit'

import Auth from '@aws-amplify/auth';
import useAuth from './auth/useAuth'


const datanacTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3d3f25',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgba(0,0,0,0.5)',
    },
    background: {
      default: '#333333',
      paper: '#f5f5f5',
    },
    info: {
      main: '#8cc63f',
    },
    divider: '#8cc63f',
  },

  typography: {
    fontSize: 12,
    htmlFontSize: 16,
    fontFamily: 'Montserrat, "sans-serif"',
    fontWeightRegular: 600,
    fontWeightMedium: 600
  },

  props: {
    MuiAppBar: {
      color: 'default',
    },

    MuiButton: {
      size: 'large',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small'
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },

  shape: {
    borderRadius: 7,
  },

  root: {
    display: 'none'
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
          {
            background: 'linear-gradient(90deg, #8FB954 0%, #D2D550 100%)',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            color: '#000000',
            border: 0,
            borderRadius: 3,
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingTop: "10px",
            paddingBottom: "10px"
          }),
          ...(ownerState.variant === 'outlined' &&
          {
            boxShadow: '0 3px 5px 2px rgba(100, 100, 100, .3)',
            borderWidth: '1px',
            borderColor: '#c0c0c0',
            borderRadius: 3,
            paddingLeft: "21px",
            paddingRight: "21px",
            paddingTop: "10px",
            paddingBottom: "10px"
          }),
        })
      }
    },
  }
});



export const neutralizeBack = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
  };
};
neutralizeBack();


function AppWorkspace() {
  const { globalState, globalDispatch } = useContext(AuthenticationContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);


  const { signIn, signOut, user, isSignedIn } = useAuth({
    options: {
      userPoolId: 'us-east-2_CrGQj2ghJ',
      userPoolWebClientId: 'a9fmnjqs8vpjmfosogq1lf12r',
      oauth: {
        domain: 'datanac-test.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: 'http://localhost:3000',
        redirectSignOut: 'http://localhost:3000',
        region: 'us-east-2',
        responseType: 'code'
      }
    }
  })

  useEffect(() => {
    const loadMyUser = async () => {
      const session = await Auth.currentSession();
      const token = session.getIdToken();
      const jwt = token.getJwtToken();

      globalDispatch({
        type: 'login',
        payload: {
          bearer_token: jwt,
          refresh_token: 'noop',

          producer_token: token?.payload['custom:producer_token'],
          agent_token: token?.payload['custom:agent_token'],
          user_role: token?.payload['custom:datanac_user_role']
        },
      });
    }
    loadMyUser();
  }, [user?.username]);


  return (
    <>
      <ThemeProvider
        theme={datanacTheme}
      >
        <AppBar position="static"
          sx={{
            height: "75px",
            lineHeight: "70px",
            background: "linear-gradient(rgb(55, 124, 15, 0.4), rgb(208, 215, 40, 0.4)), url(/images/header-background.png);",
            borderBottom: "2px solid rgb(255, 255, 255, 0.3)"
          }}>
          <Toolbar>
            <Box
              sx={{
                height: "70px",
                lineHeight: "70px",
                width: "100%",
                display: 'flex',
                alignItems: 'center'
              }}>

              {(globalState.user_role == "agent" || globalState.user_role == "admin") &&
                <Button
                  sx={{
                    minWidth: 0,
                    borderRadius: 1,
                    mr: 2,
                    p: 1,
                    fontColor: 'black',
                    backgroundColor: 'white', backgroundImage: 'linear-gradient(to right, #ECE9E6 0%, #FFFFFF  51%, #ECE9E6  100%)',
                  }}
                  onClick={() => setIsDrawerOpen(true)}
                >
                  <MenuOutlined sx={{ m: 0 }} />
                </Button>
              }

              <Typography variant="h2"
                sx={{
                  display: { xs: "none", md: "inline-block" },
                  lineHeight: "normal",
                  fontFamily: 'Bitter', fontSize: "37px", fontWeight: "bold", color: "#ffffff",
                }}
              >
                DATANAC
              </Typography>

              <Typography variant="h2"
                sx={{
                  display: { xs: "none", md: "inline-block" },
                  lineHeight: "normal",
                  fontFamily: 'Bitter', fontSize: "37px", fontWeight: "bold", color: "#ffffff",
                  ml: 3, mr: 3,
                }}
              >
                |
              </Typography>

              <Typography variant="h2"
                sx={{
                  display: "inline-block",
                  lineHeight: "normal",
                  fontFamily: 'Bitter',
                  fontSize: { xs: "20px", md: "32px" },
                  fontWeight: "bold", color: "#ffffff",
                }}
              >
                Dashboard
              </Typography>
            </Box>


          </Toolbar>

          <Drawer
            anchor={'left'}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
          >
            <Box sx={{ minWidth: '20vw', maxWidth: '50vw', m: 2, p: 0 }}>
              <DashboardContextPicker />
            </Box>
          </Drawer>
        </AppBar>

        <div style={{ paddingBottom: "1in" }}>
          {isSignedIn ? (
            <YieldsDashboard />
          ) : (
            <button onClick={() => signIn()}>Login</button>
          )}
        </div>

      </ThemeProvider>
    </>
  )
}

export default AppWorkspace;
