import { React, useState, useMemo, useEffect, useReducer, useContext, createContext } from 'react'
import {
    AppBar, Box, Button, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography,
    useMediaQuery, Paper,
    Autocomplete
} from '@mui/material';
import { DataFormSelect, propUpdateReducer } from '@datanac/datanac-mui-toolkit';
import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";
import { AuthenticationContext } from "@datanac/datanac-mui-toolkit/dist/components";

export default function DashboardContextPicker({
    children
}) {
    const { globalState, globalDispatch } = useContext(AuthenticationContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const [state, dispatch] = useReducer(propUpdateReducer, {});

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        const payload = {};
        payload[event.target.name] = value;

        dispatch({
            type: 'updateList',
            payload: payload,
        });
    }

    return (
        <>
            <Paper sx={{ m: 0, p: 0, height: '100%' }}>
                {/* <DataFormSelect
                    value={state.state_name || ""}
                    name="state_name"
                    isRequired={false}
                    doAllowEmptyElement={true}
                    onChange={handleChangeState}
                    onBlur={null}
                    objectName="State"
                    objectNameLabel="State"
                    itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                    itemGetKey={(item) => item.state_name}
                    itemGetLabel={(item) => item.state_name}
                    doFetch={state.reinsurance_year != null}
                /> */}
            </Paper>
        </>
    )
}
