import { React, useState, useMemo, useEffect, useReducer, useContext, createContext } from 'react'
import { AppBar, Box, Button, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, Paper } from '@mui/material';
import { AuthenticationContext } from "@datanac/datanac-mui-toolkit/dist/components";

export default function ChartWrapper({
    children
}) {
    const { globalState, globalDispatch } = useContext(AuthenticationContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    return (
        <>
            <Paper sx={{ m: 0, p: 0, height: '100%', minHeight: '251px', height: '251px' }}>
                {children}
            </Paper>
        </>
    )
}
