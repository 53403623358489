import Paper from '@mui/material/Paper'
import GridLayout from "react-grid-layout";
import { Responsive, WidthProvider } from "react-grid-layout";

import { React, useState, useMemo, useEffect, useReducer, useContext, createContext } from 'react'
import { AppBar, Box, Button, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import { ChartBase, HistoricalYieldTrendChart } from '@datanac/datanac-visualization-toolkit'
import {
    CHART_TYPE_TIMESERIES_COUNTY, CHART_TYPE_TIMESERIES_STATE, CHART_TYPE_BAR_COUNTY, CHART_TYPE_MAP_STATE, CHART_TYPE_TABLE
} from '@datanac/datanac-visualization-toolkit'
import { CloseOutlined } from '@mui/icons-material';
import _ from 'lodash';
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import ChartWrapper from "../dashboard/ChartWrapper";
import { DataGrid } from "@mui/x-data-grid";
import { formatPrice } from "../utility/formatters";
import { mean, std, variance } from 'mathjs';
import { YieldVarianceChart } from "@datanac/datanac-visualization-toolkit/dist/Components/rma-adm/YieldVarianceChart";
import { DatesChart } from "@datanac/datanac-visualization-toolkit/dist/Components/rma-adm/DatesChart";
import { AuthenticationContext } from "@datanac/datanac-mui-toolkit/dist/components";
import { ApiHelper, RmaApiHelper } from '@datanac/datanac-api-toolkit';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function YieldsDashboard() {
    const { globalState, globalDispatch, sessionManager } = useContext(AuthenticationContext);
    const _ApiHelper = new ApiHelper({
        baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE,
        config: {},
        sessionManager: sessionManager
    });
    const _RmaApiHelper = new RmaApiHelper({
        baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE,
        config: {},
        sessionManager: sessionManager
    });

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    // const countyPractice = {
    //     reinsurance_year: "2023",

    //     commodity_name: "Corn",
    //     type_name: "Grain",
    //     practice_name: "Irrigated",

    //     state_name: "Colorado",
    //     county_name: "Weld"
    // };
    const countyPractice = {
        reinsurance_year: "2023",

        commodity_name: "Cotton",
        type_name: "No Type Specified",
        practice_name: "Irrigated",

        state_name: "Mississippi",
        county_name: "Tunica"
    };
    const filter = {
        filterXMinimum: null,
        filterxMaximum: null
    }

    const [priceData, setPriceData] = useState([]);
    const _getPriceDataAsync = async () => {
        try {
            const _countyParams = {
                ...countyPractice,
                state_name: countyPractice?.state_name || countyPractice?.location_state_name,
                // county_name: countyPractice?.county_name || countyPractice?.location_county_name,
            };
            delete _countyParams.county_name;
            delete _countyParams.location_county_name;
            delete _countyParams.reinsurance_year;

            const __data = await _RmaApiHelper.selectPrices(_countyParams);

            const current_projected_price = (__data.find(r =>
                r.state_name == (countyPractice?.state_name || countyPractice?.location_state_name)
                && r.county_name == (countyPractice?.county_name || countyPractice?.location_county_name)
                && r.reinsurance_year == getCurrentReinsuranceYear()
            ) || {})?.projected_price;

            const _data = __data?.map(y => {
                return ({
                    ...y,
                    current_projected_price: current_projected_price
                })
            });

            setPriceData(_data);
            // console.log(_countyParams, _data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    const [historicalYieldTrendData, setHistoricalYieldTrendData] = useState([]);
    const _getHistoricalYieldTrendDataAsync = async () => {
        try {
            const _countyParams = {
                ...countyPractice,
                state_name: countyPractice?.state_name || countyPractice?.location_state_name,
                // county_name: countyPractice?.county_name || countyPractice?.location_county_name,
            };
            delete _countyParams.county_name;
            delete _countyParams.location_county_name;

            const _data = await _RmaApiHelper.selectHistoricalYieldTrend(_countyParams);
            setHistoricalYieldTrendData(_data);
            // console.log(_data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    const [nassAcresPlantedData, setNassAcresPlantedData] = useState([]);
    const _getNassAcresPlantedDataAsync = async () => {
        try {
            const _countyParams = {
                commodity: countyPractice?.commodity_name,
                state_name: countyPractice?.state_name || countyPractice?.location_state_name,
            };

            const _data = await _ApiHelper.selectObject("NASS.QS", "AcresPlanted", _countyParams);

            setNassAcresPlantedData(_data);
            // console.log(_data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    const [nassAnnualSurveyData, setNassAnnualSurveyData] = useState([]);
    const _getNassAnnualSurveyData = async () => {
        try {
            const _countyParams = {
                commodity: countyPractice?.commodity_name,
                state_name: countyPractice?.state_name || countyPractice?.location_state_name,
                statistic_cat: "AREA HARVESTED"
            };

            const _data = (await _ApiHelper.selectObject("NASS.QS", "AnnualSurvey", _countyParams))
                ?.filter(r => r.county_name != null);

            setNassAnnualSurveyData(_data);
            // console.log(_data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    const [summaryOfBusinessData, setSummaryOfBusinessData] = useState([]);
    const _getSummaryOfBusinessData = async () => {
        try {
            const _countyParams = {
                ...countyPractice
            };
            delete _countyParams.county_name;
            delete _countyParams.location_county_name;
            delete _countyParams.reinsurance_year;

            const _data = _(await _ApiHelper.selectObject("RMA.summary_of_business", "SummaryOfBusiness", _countyParams))
                .groupBy(r => `${r.commodity_year}/${r.state_name}/${r.county_name}/${r.commodity_name}`)
                .map(grp => {
                    const rr = {
                        commodity_year: grp[0]?.commodity_year,
                        state_name: grp[0]?.state_name?.trim(),
                        county_name: grp[0]?.county_name?.trim(),
                        commodity_name: grp[0]?.commodity_name?.trim(),

                        indemnity_amount: _.sum(grp.map(rr => rr.indemnity_amount)),
                        total_premium_amount: _.sum(grp.map(rr => rr.total_premium_amount)),
                        subsidy_amount: _.sum(grp.map(rr => rr.subsidy_amount)),
                    };

                    rr.producer_premium_amount = rr.total_premium_amount - rr.subsidy_amount;
                    rr.loss_ratio = (rr.total_premium_amount != 0) ? rr.indemnity_amount / rr.total_premium_amount : 0.00;
                    rr.net_insurance_benefit = rr.producer_premium_amount - rr.indemnity_amount;

                    return rr;
                })
                .value();

            setSummaryOfBusinessData(_data);
            // console.log(_data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    const [summaryOfBusinessCauseOfLossData, setSummaryOfBusinessCauseOfLossData] = useState([]);
    const _getSummaryOfBusinessCauseOfLossData = async () => {
        try {
            const _countyParams = {
                ...countyPractice
            };
            delete _countyParams.county_name;
            delete _countyParams.location_county_name;
            delete _countyParams.reinsurance_year;
            delete _countyParams.type_name;
            delete _countyParams.practice_name;

            const _data = _(await _ApiHelper.selectObject("RMA.summary_of_business", "SummaryOfBusinessCauseOfLoss", _countyParams))
                .groupBy(r => `${r.commodity_year}/${r.state_name}/${r.county_name}/${r.commodity_name}`)
                .map(grp => {
                    const rr = {
                        commodity_year: grp[0]?.commodity_year,
                        state_name: grp[0]?.state_name?.trim(),
                        county_name: grp[0]?.county_name?.trim(),
                        commodity_name: grp[0]?.commodity_name?.trim(),

                        indemnity_amount: _.sum(grp.map(rr => rr.indemnity_amount)),
                        total_premium_amount: _.sum(grp.map(rr => rr.total_premium)),
                        subsidy_amount: _.sum(grp.map(rr => rr.subsidy)),
                        producer_premium_amount: _.sum(grp.map(rr => rr.producer_paid_premium)),
                    };

                    rr.loss_ratio = (rr.total_premium_amount != 0) ? rr.indemnity_amount / rr.total_premium_amount : 0.00;
                    rr.net_insurance_benefit = rr.producer_premium_amount - rr.indemnity_amount;

                    return rr;
                })
                .value();

            setSummaryOfBusinessCauseOfLossData(_data);
            // console.log(_data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    const [budgetData, setBudgetData] = useState([]);
    const _getBudgetData = async () => {
        try {
            const _countyParams = {
                ...countyPractice,
                practice: countyPractice?.practice_name
            };
            delete _countyParams.county_name;
            delete _countyParams.location_county_name;
            delete _countyParams.reinsurance_year;
            delete _countyParams.year;
            delete _countyParams.type_name;
            delete _countyParams.practice_name;

            const _data = _(await _ApiHelper.selectObject("FSA.Extension", "Budget", _countyParams))
                .groupBy(r => `${r.year}/${r.state_name}/${r.county_name}/${r.commodity_name}`)
                .map(grp => {
                    const rr = {
                        year: grp[0]?.year,
                        state_name: grp[0]?.state_name?.trim(),
                        county_name: grp[0]?.county_name?.trim(),
                        commodity_name: grp[0]?.commodity_name?.trim(),

                        total: _.mean(grp.map(rr => rr.total)),
                    };

                    return rr;
                })
                .value();

            setBudgetData(_data);
            // console.log(_data);
        } catch (ex) {
            // alert(ex?.error?.message || ex);
            throw ex;
        }
    }

    useEffect(() => {
        _getPriceDataAsync();
        _getHistoricalYieldTrendDataAsync();
        _getNassAcresPlantedDataAsync();
        _getNassAnnualSurveyData();
        _getSummaryOfBusinessData();
        _getSummaryOfBusinessCauseOfLossData();
        _getBudgetData();
    }, [countyPractice.state_name, countyPractice.county_name, countyPractice.location_state_name, countyPractice.location_county_name]);


    return (
        <>
            <Grid container spacing={1} padding={1} sx={{ pl: { xs: 0, lg: 1 }, pr: { xs: 0, lg: 1 } }} alignItems='stretch'>
                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <HistoricalYieldTrendChart countyPractice={countyPractice} api={_ApiHelper} size={isMobile ? 'small' : null} />
                    </ChartWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <YieldVarianceChart countyPractice={countyPractice} api={_ApiHelper} size={isMobile ? 'small' : null} />
                    </ChartWrapper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <ChartBase
                            title='Acres Planted'
                            fieldNameX={'year'}
                            fieldNameY={'area_planted'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={nassAcresPlantedData}
                            filterXMinimum={Math.max(filter.filterXMinimum, 1970)}
                            filterxMaximum={filter.filterxMaximum}
                            allowedChartTypes={[
                                CHART_TYPE_TIMESERIES_STATE,
                                CHART_TYPE_BAR_COUNTY,
                                CHART_TYPE_MAP_STATE,
                                CHART_TYPE_TABLE,
                            ]}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <ChartBase
                            title='Acres Harvested'
                            fieldNameX={'year'}
                            fieldNameY={'yield'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={nassAnnualSurveyData?.filter(r => r.statistic_cat == "AREA HARVESTED")}
                            filterXMinimum={Math.max(filter.filterXMinimum, 1970)}
                            filterxMaximum={filter.filterxMaximum}
                            allowedChartTypes={[
                                CHART_TYPE_TIMESERIES_STATE,
                                CHART_TYPE_BAR_COUNTY,
                                CHART_TYPE_MAP_STATE,
                                CHART_TYPE_TABLE,
                            ]}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>


                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <ChartBase
                            title='Projected Price'
                            fieldNameX='reinsurance_year'
                            fieldNameY={'projected_price'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={'current_projected_price'}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={priceData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <ChartBase
                            title='Harvest Price'
                            fieldNameX='reinsurance_year'
                            fieldNameY={'harvest_price'}
                            fieldNameY2={'projected_price'}
                            fieldNameY3={null}
                            fieldNameYHighlight={'current_projected_price'}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={priceData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <DatesChart title='Insurance Dates' countyPractice={countyPractice} api={_ApiHelper} size={isMobile ? 'small' : null} />
                    </ChartWrapper>
                </Grid>


                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <ChartBase
                            title='Producer Premiums'
                            fieldNameX='commodity_year'
                            fieldNameY={'producer_premium_amount'}
                            fieldNameY2={'indemnity_amount'}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={summaryOfBusinessData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>

                <Grid item xs={12} lg={4}>
                    <ChartWrapper>
                        <ChartBase
                            title='MPCI Indemnities'
                            fieldNameX='commodity_year'
                            fieldNameY={'indemnity_amount'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={summaryOfBusinessData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ChartWrapper>
                        <ChartBase
                            title='Loss Ratio %'
                            fieldNameX='commodity_year'
                            fieldNameY={'loss_ratio'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={summaryOfBusinessData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ChartWrapper>
                        <ChartBase
                            title='Cause of Loss'
                            fieldNameX='cause_of_loss_description'
                            fieldNameY={'loss_ratio'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={summaryOfBusinessCauseOfLossData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>


                <Grid item xs={12} lg={6}>
                    <ChartWrapper>
                        <ChartBase
                            title='Cost of Production'
                            fieldNameX='year'
                            fieldNameY={'total'}
                            fieldNameY2={null}
                            fieldNameY3={null}
                            fieldNameYHighlight={null}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            size={isMobile ? "small" : null}
                            data={budgetData}
                            filterXMinimum={filter.filterXMinimum}
                            filterxMaximum={filter.filterxMaximum}
                            api={_ApiHelper}
                        />
                    </ChartWrapper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    &nbsp;
                </Grid>


                {/* <Grid item xs={6} lg={4}>
                    <ChartWrapper>
                        <DataGrid hideFooter={true}
                            columns={[
                                {
                                    field: 'reinsurance_year',
                                    headerName: 'Year',
                                    flex: 1
                                },
                                {
                                    field: 'projected_price',
                                    headerName: 'Projected $',
                                    valueFormatter: (params) => formatPrice(params.value, 2),
                                    flex: 1
                                },
                                {
                                    field: 'harvest_price',
                                    headerName: 'Harvest $',
                                    valueFormatter: (params) => formatPrice(params.value, 2),
                                    flex: 1
                                },
                            ]}
                            rows={
                                priceData
                                    .filter(p => p.county_name == (countyPractice.county_name || countyPractice.location_county_name))
                                    .map((r, ii) => ({ ...r, id: ii }))
                            }
                        />
                    </ChartWrapper>
                </Grid> */}

            </Grid>
        </>
    )
}
